import { useEffect } from "react"
import { App as OriginalApp } from "../../../../../js/components/App/App"

export const App = (props) => {
  return (
    <OriginalApp {...props}>
      {props.children}
      <SpecialOfferModal />
    </OriginalApp>
  )
}

const SpecialOfferModal = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.src = "https://apiv2.popupsmart.com/api/Bundle/377779"

    document.head.append(script)
  }, [])

  return null
}
